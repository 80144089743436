import { gql, useQuery } from "@apollo/client";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styles from "../styles/pages/Dashboard.module.css";

const GET_ASSIGNMENTS_QUERY = gql`
  query {
    location_occurences {
      count
      location
    }
  }
`;

const Dashboard = () => {
  const { data } = useQuery(GET_ASSIGNMENTS_QUERY);

  return (
    <>
      <title>Dashboard - Buber Quest</title>

      <div>
        <h2 className={styles.title}>Dashboard</h2>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Locatie naam</TableCell>
                <TableCell align="right">Aantal</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.location_occurences.map((occurence) => (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  key={occurence.location}
                >
                  <TableCell component="th" scope="row">
                    {occurence.location}
                  </TableCell>
                  <TableCell align="right">{occurence.count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default Dashboard;
